import { useCallback, useEffect, useState } from 'react';
import ReactFlow, {  Controls, applyEdgeChanges, applyNodeChanges, addEdge } from 'react-flow-renderer';
import {initialNodes, initialEdges} from './constants';
import TextUpdaterNode from './TextUpdaterNode';

const nodeTypes = { textUpdater: TextUpdaterNode };


export const ReactFlowTest = () => {
  const [nodes, setNodes] = useState([...initialNodes]);
  const [edges, setEdges] = useState([...initialEdges]);
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => {
      window.console.log('onEdgesChange', eds)
      return applyEdgeChanges(changes, eds)
    }),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => {
      window.console.log('onConnect setEdges', connection)
      return setEdges((eds) => {
        window.console.log('onConnect addEdge', eds)
        return addEdge(connection, eds)
      })
    },
    [setEdges]
  );
  const addNode = () => {
    setNodes([
      ...nodes.filter(({type}) => type !== 'output'),
      {
        id: `${Date.now()}`,
        type:'default',
        data: { label: 'Default Node' },
        position: { x: 50, y: 50 },
      },
      ...nodes.filter(({type}) => type === 'output'),
    ])
  }
  useEffect(() => window.console.log('EDGE UPDATE', edges), [edges])
  return (
    <div>
      ------- REACT FLOW -------<br />
      <hr />
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%', height: '70vh', padding: 10}}>
          <button onClick={addNode}>ADD NODE</button>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
          >
            <Controls />
          </ReactFlow>
        </div>
      </div>
      <div style={{textAlign: 'left', padding: 40}}>
        PROS<br />
        <ul>
          <li>
            Rich documentation
          </li>
          <li>
            Pro version
          </li>
          <li>
            Easy Customizations
          </li>
          <li>
            Component based
          </li>
          <li>
            Drag n drop available
          </li>
        </ul>
        CONS<br />
        <ul>
          <li>
            Multi port list node has shown problems while reponening the flow.
          </li>
        </ul>
      </div>
    </div>
  );
}
