import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

const handleStyle = { 
  display: 'flex', 
  flexDirection: 'row', 
  position: 'static', 
  color: 'white', 
  borderRadius: 0, 
  backgroundColor: 'unset',
  backgroundSize: 'contain',
  backgroundRepeat:'no-repeat',
  width: 20, 
  height: 20, 
  backgroundImage: `url(https://cdn-icons-png.flaticon.com/512/591/591881.png)`,
  marginBottom: 5
}

function TextUpdaterNode() {
  const [handles, setHandle] = useState(['a', 'b'])
  return (
    <div className="text-updater-node" style={{ display: 'flex', border: '1px solid #000', padding: 0, borderRadius: 5, flexDirection: 'column'}}>
      <Handle type="target" position={Position.Top} />
      Custom node
      <br />
      <br />
      <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: 'auto', alignItems: 'flex-end', borderTop: '1px solid #CCC'}}>
        <br />
        {handles.map((handle) => <Handle title={handle} type="source" position={Position.Right} id={handle} style={handleStyle} />)}
        <button onClick={() => {
          setHandle([...handles, `test`+ Date.now()])
        }}>+</button>
      </div>
    </div>
  );
}

export default React.memo(TextUpdaterNode);
