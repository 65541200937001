export const BotFlow = () => <div>
    Bot flow
    <a href="http://sebastien.drouyer.com/jquery.flowchart-demo/"> jQuery documentation link</a>
    <br /><br />
    <div style={{textAlign: 'left', padding: 40}}>
            PROS<br />
            <ul>
            <li>
                Matches with Bot flow requirement
            </li>
            <li>
                Documentation available
            </li>
            </ul>
            CONS<br />
            <ul>
            <li>
                made with jQuery
            </li>
            <li>
                Customizations might be difficult
            </li>
            </ul>
      </div>
</div>