import createEngine, { 
    DefaultLinkModel, 
    DefaultNodeModel,
    DiagramModel 
} from '@projectstorm/react-diagrams';

import styles from './styles.module.scss'

import {
    CanvasWidget
} from '@projectstorm/react-canvas-core';
import { useEffect, useState } from 'react';
import DiamondNodeModel from './DiamondNodeModel';

const getEngine = () => {
     // create an instance of the engine with all the defaults
     const engine = createEngine();
     // node 1
     const node1 = new DefaultNodeModel({
         name: 'Node 1',
         color: 'rgb(0,192,255)',
     });
     node1.setPosition(100, 100);
     let port1 = node1.addOutPort('Out');
 
     // node 2
     const node2 = new DefaultNodeModel({
         name: 'Node 1',
         color: 'rgb(0,192,255)',
     });
     node2.setPosition(250, 250);
     node2.addInPort('In1')
     let port2 = node2.addOutPort('Out');
     node2.addOutPort('Out1');
     node2.addOutPort('Out2');
 
     // link them and add a label to the link
     const link = port1.link(port2);
 
     const model = new DiagramModel();
     model.addAll(node1, node2, link);
     engine.setModel(model);
     return engine
}

export const ReactDiagramTest = () => {
    // const [engine, setEngine] = useState(getEngine())
    const engine = getEngine()
    let newNode = null

    const addNode = () => {
        newNode = new DefaultNodeModel({
            name: 'Node 1'+ Date.now(),
            color: 'rgb(0,192,255)',
        });
        newNode.addOutPort('Out1');
        newNode.addInPort('In');
        engine.model.addNode(newNode)
        window.console.log('addNode>>', engine)
        // engine.setModel(engine.model)
        engine.repaintCanvas()

    }

    const addPort = () => {
        newNode.addOutPort('Out2' + Date.now())
        engine.repaintCanvas()

    }

    window.console.log('ReactDiagramTest>>', engine.model.getNodes())


    return <div>
        --------REACT DIAGRAM---------<br />
        <hr />
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
            &nbsp;
            <div>
                <button onClick={addNode}>ADD NODE</button><br />
                <button onClick={addPort}>ADD PORT</button>
            </div>
            <CanvasWidget className={styles.canvas} engine={engine} />
        </div>
        <div style={{textAlign: 'left', padding: 40}}>
            PROS<br />
            <ul>
            <li>
                Drag n drop available
            </li>
            <li>
                Custom ports available
            </li>
            <li>
                Basic Documentation available
            </li>
            </ul>
            CONS<br />
            <ul>
            <li>
                Purely object oriented, working with React is difficult.
            </li>
            <li>
                Not easy to customize
            </li>
            <li>
                Paths might not be customizable
            </li>
            </ul>
      </div>
    </div>
}