import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ReactFlowTest } from './ReactFlowTest';
import FlowChartTest from './FlowChartTest';
import { ReactDiagramTest } from './ReactDiagramTest';
import { BotFlow } from './BotFlowTest';

const links = {
  REACT_FLOW: '/react-flow',
  FLOW_CHART: '/flow-chart',
  REACT_DIAGRAM: '/react-diagram',
  BOT_FLOW: '/bot-flow'
}


function App() {
  return (
    <div className="App">
      <div style={{backgroundColor: '#CCC', padding: 10, marginBottom: 10}}>
        <a href={links.REACT_FLOW}>React flow</a>&nbsp;&nbsp;&nbsp;
        <a href={links.FLOW_CHART}>Flow chart</a>&nbsp;&nbsp;&nbsp;
        <a href={links.REACT_DIAGRAM}>React diagram</a>&nbsp;&nbsp;&nbsp;
        <a href={links.BOT_FLOW}>Bot flow</a>&nbsp;&nbsp;&nbsp;
      </div>
      <Router>
          <Routes>
            <Route path={links.REACT_FLOW} element={<ReactFlowTest />} />
            <Route path={links.FLOW_CHART} element={<FlowChartTest />} />
            <Route path={links.REACT_DIAGRAM} element={<ReactDiagramTest />} />
            <Route path={links.BOT_FLOW} element={<BotFlow />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
