// DiamondNodeModel.ts
import { DefaultPortModel, NodeModel, PortModelAlignment } from '@projectstorm/react-diagrams';

// this can be further extended for more complicated node types
class DiamondNodeModel extends NodeModel {
    constructor() {
        super({
            type: 'diamond'
        });
        this.addPort(new DefaultPortModel(PortModelAlignment.TOP));
        this.addPort(new DefaultPortModel(PortModelAlignment.LEFT));
        this.addPort(new DefaultPortModel(PortModelAlignment.BOTTOM));
        this.addPort(new DefaultPortModel(PortModelAlignment.RIGHT));
    }
}

export default DiamondNodeModel