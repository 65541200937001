import React, { useCallback, useState } from "react";
import Flowchart from "flowchart-react";
import styles from './style.module.scss';

const FlowChartTest = () => {
  const [nodes, setNodes] = useState([
    {
      type: "start",
      title: "Start",
      x: 150,
      y: 190,
      id: 1604410569920
    },
    {
      type: "end",
      title: "End",
      x: 500,
      y: 190,
      id: 1604410572363
    },
    {
      x: 330,
      y: 190,
      id: 1604410575428,
      title: "Joyce",
      type: "operation"
    },
    {
      x: 330,
      y: 300,
      id: 1604410591865,
      title: () => {
        return "No approver";
      },
      type: "operation"
    }
  ]);
  const [conns, setConns] = useState([
    {
      source: { id: 1604410569920, position: "right" },
      destination: { id: 1604410575428, position: "left" },
      id: 1604410587907,
      type: "success"
    },
    {
      source: { id: 1604410575428, position: "right" },
      destination: { id: 1604410572363, position: "left" },
      id: 1604410590524,
      type: "success"
    },
    {
      source: { id: 1604410569920, position: "bottom" },
      destination: { id: 1604410591865, position: "left" },
      id: 1604410596866,
      type: "success"
    },
    {
      source: { id: 1604410591865, position: "right" },
      destination: { id: 1604410572363, position: "bottom" },
      id: 1604410599205,
      type: "success"
    }
  ]);

  const handleCreateNode = useCallback(
    (event, zoom) => {
      const point = {
        x: event.nativeEvent.offsetX / zoom,
        y: event.nativeEvent.offsetY / zoom,
        id: +new Date()
      };
      let nodeData;
      if (!nodes.find((item) => item.type === "start")) {
        nodeData = {
          type: "start",
          title: "Start",
          ...point
        };
      } else if (!nodes.find((item) => item.type === "end")) {
        nodeData = {
          type: "end",
          title: "End",
          ...point
        };
      } else {
        nodeData = {
          ...point,
          title: "New",
          type: "operation"
        };
      }
      setNodes((prevState) => [...prevState, nodeData]);
    },
    [nodes]
  );

  const addNode = () => {
    window.alert('addNode')
  }

  return (
    <div className={styles.wrapper}>
      --------FLOW CHART---------<br /><br />
      <Flowchart
        onChange={(nodes, connections) => {
          setNodes(nodes);
          setConns(connections);
        }}
        onDoubleClick={handleCreateNode}
        style={{ width: '100%', height: '70vh' }}
        nodes={nodes}
        connections={conns}

      />
      <div style={{textAlign: 'left', padding: 40}}>
        PROS<br />
        <ul>
          <li>
            Drag n drop available
          </li>
        </ul>
        CONS<br />
        <ul>
          <li>
            Not easily customizable
          </li>
          <li>
            Lack of documentation
          </li>
          <li>
            Custom flow path not available
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FlowChartTest;
