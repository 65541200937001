const initialNodes = [
    {
      id: '1',
      type: 'input',
      data: { label: 'Input Node' },
      position: { x: 250, y: 25 },
    },
  
    {
      id: '2',
      // you can also pass a React component as a label
      data: { label: <div>Default Node</div> },
      position: { x: 100, y: 125 },
    },
    {
        id: '22',
        type: 'textUpdater',
        // you can also pass a React component as a label
        data: { label: <div>Default Node</div> },
        position: { x: 500, y: 125 },
      },
    {
      id: '3',
      type: 'output',
      data: { label: 'Output Node' },
      position: { x: 250, y: 250 },
    },
    
  ]

  const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' },
    { id: 'e2-3', source: '2', target: '3', animated: true },
    {
        "source": "1",
        "target": "5",
        "id": "reactflow__edge-1-sdfsdfdsf4"
     },
     {
        "source": "1",
        "target": "22",
        "id": "reactflow__edge-1-sdfsdfdsf4213123"
     },
     {
        "source": "22",
        "sourceHandle": "b",
        "target": "3",
        "id": "reactflow__edge-1-werwe"
     }
  ]

  export {initialNodes, initialEdges}